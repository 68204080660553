<template>
  <div class="allwh">
    <ds-header title="企服日历"></ds-header>
    <a-form layout="inline" style="margin-bottom: 10px">
      <a-form-item label="选择项目">
        <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" style="width: 200px" placeholder="请选择"
          @change="projectChange" v-model="searchForm.projectId">
          <a-select-option v-for="item in projectIdList" :key="item.projectId" :value="item.projectId">
            {{ item.projectName }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="选择场地">
        <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" style="width: 200px" placeholder="请选择"
          :allowClear="true" v-model="searchForm.siteId">
          <a-select-option v-for="item in siteList" :key="item.facility_id" :value="item.facility_id">
            {{ item.facility_name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="是否需要报名">
        <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" style="width: 200px" placeholder="请选择"
          :allowClear="true" v-model="searchForm.isApply">
          <a-select-option v-for="item in isEnrollList" :key="item.value" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px" icon="search"> 查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm" icon="redo">重置</a-button>
      </span>
    </a-form>
    <div class="displayFlex">
      <div class="flex1 calendarBox">
        <div class="displayFlex paddingHeight boderBottom" style="align-items: center">
          <span class="marginLeft16 fontWeight"></span>
          <a-week-picker v-model="nowWeek" :allowClear="false" placeholder="请选择周" @change="weekChange" />
          <span class="marginLeft16 fontWeight"> {{ dateVal[0] + ' 至 ' + dateVal[1] }} </span>
          <a-button type="primary" class="marginLeft45" @click="getTodayBut"> 今天 </a-button>
        </div>
        <div class="displayFlex boderBottom">
          <div v-for="(item, index) in dateList" :key="index"
            :class="index == 0 ? 'width60 textCenter boderLeft' : 'flex1 paddingLeft'"
            class="dateStyle displayFlex boderRight">
            <div class="" :class="today == item.date ? 'checkDate' : 'unCheckDate'">
              <div class="dateDay">
                {{ item.week }}
              </div>
              <div :class="index == 0 ? 'dateWeekTip' : 'dateWeek'">
                {{ item.day }}
              </div>
            </div>
          </div>
        </div>
        <div class="displayFlex">
          <div v-for="(item, index) in timeSoltList" :key="index" :class="index == 0 ? 'width60' : 'flex1 displayGrid'"
            class="positionRelative">
            <div class="timeChart positionRelative" v-for="(j, i) in item.timeList"
              :class="index == 0 ? 'boderLeft textCenter' : 'width100 overFlow'" :key="i">
              <div>{{ j.time }}</div>
              <div v-for="(el, e) in j.activeList" class="checkTimeBox" @click="activeBut(el)"
                :class="judgeShowColor(el)" :key="e">
                <div>
                  <div class="checkTimeBoxName">{{ el.activityTitle }}</div>
                  <div class="checkTimeBoxTime">{{ el.time }}</div>
                  <div class="checkTimeBoxAddress">地点：{{ el.siteName }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import * as api from '../../../api/enterpriseServiceActivitiesManagement';
export default {
  title: '企服日历',
  name: 'enterpriseCalendar',
  data() {
    return {
      dateList: [], //顶部日期和星期回显
      timeSoltList: [], //左侧时间段
      checkTime: [], //选中的日期时间段
      firstWeekDay: '', //当前周第一天时间戳
      lastWeekDay: '', //当前周最后一天时间戳
      today: '',
      siteList: [], //场地
      isEnrollList: [
        { label: '是', value: 0 },
        { label: '否', value: 1 },
      ],
      projectIdList: [],
      checkFacilityData: '', //选择的会议室
      searchForm: {},
      dataList: [],
      nowWeek: '',
      dateVal: [], //展示周对应的日期
    };
  },
  mounted() {
    this.FIND_ENTERPRIES_TYPE();
    this.getTodayVal();
    this.getTimeStamp();
    this.getWeek();
  },
  methods: {
    //获取当前日期和本周的开始和结束时间
    getTodayVal() {
      this.nowWeek = moment();
      let year = moment().year();
      let num = moment().week();
      let handleYear = moment(new Date(String(year)));
      let startDate = handleYear.week(num).startOf('week').format('YYYY-MM-DD');
      let endDate = handleYear.week(num).endOf('week').format('YYYY-MM-DD');
      this.dateVal = [startDate, endDate];
    },
    //顶部星期和日期回显
    getWeek() {
      let firstDay = this.firstWeekDay;
      let dateList = [{ day: '时间', week: '', date: '' }];
      const week = ['周一', '周二', '周三', '周四', '周五', '周六', '周日'];
      for (let i = 0; i < 7; i++) {
        let date = i * 86400000 + firstDay;
        let obj = {
          day: moment(date).format('DD'),
          week: week[i],
          date: moment(date).format('YYYY-MM-DD'),
        };
        dateList.push(obj);
      }
      this.dateList = dateList;
    },
    //根据会议室获取时间段
    getTimeSoltData() {
      facilityDashboardList({
        facilityId: this.checkFacilityData,
        startDate: moment(this.firstWeekDay).format('YYYY-MM-DD'),
        endDate: moment(this.lastWeekDay).format('YYYY-MM-DD'),
      }).then((res) => {
        if (res.code == 200) {
          this.checkTime = res.payload;
        }
      });
    },
    //左侧时间段回显
    getTimeSolt() {
      let list = [];
      for (let j = 0; j < 8; j++) {
        list.push({ timeList: [] });
        if (j == 0) {
          list[j].timeList.push({ time: '上午', activeList: [] }, { time: '下午', activeList: [] });
        } else {
          list[j].timeList.push(
            {
              time: '',
              date: this.dateList[j].date,
              activeList: this.ifShowActiveData(1, this.dateList[j].date),
            },
            {
              time: '',
              date: this.dateList[j].date,
              activeList: this.ifShowActiveData(2, this.dateList[j].date),
            }
          );
        }
      }
      console.log(list, 'list');
      this.timeSoltList = list;
    },
    getTodayBut() {
      this.getTodayVal();
      this.getTimeStamp();
      this.getWeek();
      this.getActiveData();
      // this.getTimeSoltData();
    },
    //获取当前周第一天和最后一天时间戳
    getTimeStamp() {
      let date = new Date();
      const day = date.getDay(); // 👉️ get day of week
      const diff = date.getDate() - day + (day === 0 ? -6 : 1);
      let first = Date.parse(new Date(date.setDate(diff)));
      this.firstWeekDay = first;
      this.lastWeekDay = first + 86400000 * 6;
      this.today = moment().format('YYYY-MM-DD');
    },
    // 周Change事件
    weekChange(date, dateString) {
      let year = dateString.substring(0, 4);
      let num = dateString.substring(5, 7);
      if (num.includes('周')) {
        num = num.substring(0, 1);
      }
      let startDate = date.week(Number(num)).startOf('week').format('YYYY-MM-DD');
      this.firstWeekDay = moment(startDate).valueOf();
      let endDate = date.week(Number(num)).endOf('week').format('YYYY-MM-DD');
      this.dateVal = [startDate, endDate];
      this.getWeek();
      this.getActiveData();
    },
    //项目Change事件
    projectChange() {
      if (this.searchForm.projectId) {
        this.getSiteList();
        this.getActiveData();
      } else {
        this.searchForm.siteId = undefined;
        this.siteList = [];
      }
    },
    // 查询场地列表
    async getSiteList() {
      try {
        const res = await api.getplacList(this.searchForm.projectId);
        if (res.code == 'SUCESS') {
          this.siteList = res.data;
        }
      } catch (error) {

      }
    },
    //查询活动数据
    getActiveData() {
      let obj = {
        ...this.searchForm,
        activeTimeStart: this.dateVal[0],
        activeTimeEnd: this.dateVal[1],
      };
      api.getActivityPeriod(obj).then((res) => {
        if (res.code == '200') {
          this.dataList = res.data;
          this.getTimeSolt();
        }
      });
    },
    //根据日期展示对应的活动
    ifShowActiveData(i, date) {
      let list = [];
      this.dataList.forEach((element) => {
        let dateVal = element.startTime.slice(0, 10);
        let time = element.startTime.slice(-5);
        if (date == dateVal) {
          let startTime = moment('2024-01-01 13:00:00').valueOf();
          let endTime = moment('2024-01-01 ' + time).valueOf();
          element.time = element.startTime.slice(11, 16) + ' 至 ' + element.endTime.slice(11, 16);
          let count = startTime - endTime;
          if (count > 0 && i == 1) {
            list.push(element);
          } else if (count <= 0 && i == 2) {
            list.push(element);
          }
        }
      });
      return list;
    },
    //根据活动状态展示对应的颜色
    judgeShowColor(data) {
      let val = '';
      if (data.yesOrNo == 0) {
        val = 'colorGrey';
      } else if (data.yesOrNo == 1) {
        val = 'colorGreen';
      } else if (data.yesOrNo == 2) {
        val = 'colorYellow';
      }
      return val;
    },
    // 适用项目
    async FIND_ENTERPRIES_TYPE() {
      api.FINDPROJECT().then((res) => {
        if (res.code == 200) {
          this.projectIdList = res.data;
          this.searchForm.projectId = res.data[0].projectId;
          this.getSiteList();
          this.getActiveData();
        }
      });
    },
    search() {
      this.getActiveData();
    },
    resetSearchForm() {
      this.searchForm = this.$options.data.call(this).searchForm;
      this.searchForm.projectId = this.projectIdList[0].projectId;
      this.siteList = [];
      this.getSiteList();
      this.getActiveData();
    },
    //未开始的活动可以跳编辑，已开始的已结束的跳查看
    activeBut(data) {
      this.$router.push({
        path: '/enterpriseServiceActivitiesManagement/detail',
        query: {
          id: data.activityId,
        },
      });
      // console.log(data);
      // debugger;
      // if (data.yesOrNo == 2) {
      //   this.$router.push({
      //     path: '/enterpriseServiceActivitiesManagement/add',
      //     query: {
      //       id: data.activityId,
      //     },
      //   });
      // } else {

      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.displayFlex {
  display: flex;
}

.displayGrid {
  display: grid !important;
}

.flex1 {
  flex: 1;
  // text-align: center;
}

.flexWidth {
  width: 334px;
  padding-right: 20px;
}

.width100 {
  width: 100%;
}

.overFlow {
  overflow-y: auto;
}

.timeChart {
  height: 390px;
  border-right: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  text-align: right;
  // display: inline-block;
}

.boderRight {
  border-right: 1px solid #eeeeee;
}

.clearBottom {
  border-bottom: 0px !important;
}

.positionRelative {
  position: relative;
}

.marginLeft12 {
  margin-left: 12px;
}

.boderLeft {
  border-left: 1px solid #eeeeee;
}

.boderBottom {
  border-bottom: 1px solid #eeeeee;
}

.textCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkTimeBox {
  height: 68px;
  font-size: 10px;
  text-align: left;
  cursor: pointer;
  margin: 8px 0px 8px 8px;
  padding: 6px;

  .checkTimeBoxName {
    font-size: 12px;
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .checkTimeBoxTime {
    font-size: 12px;
  }

  .checkTimeBoxAddress {
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.colorGrey {
  color: #999999;
  background: #eeeeee;
}

.colorGreen {
  color: #0dbe75;
  background: #e5f7f0;
}

.colorYellow {
  color: #ffaa56;
  background: #fff1e6;
}

.checkTime {
  // opacity: 0.5;
  width: 100%;
  height: 10px;
  position: absolute;
  top: 0%;
  color: #fff;
  font-size: 10px;
  cursor: pointer;
}

.font17 {
  font-size: 17px;
}

.displayFlexAttribute {
  align-items: center;
  justify-content: space-between;
}

.padding {
  padding: 20px 14px;
}

.meetingImage {
  width: 74px;
  height: 74px;
  border-radius: 5px;
}

.marginLeft16 {
  margin-left: 16px;
}

.maxWidth {
  max-width: 200px;
}

.meetingTitle {
  font-weight: bold;
  color: #3d3d3d;
}

.margintop {
  margin-top: 8px;
}

.font14 {
  font-size: 14px;
}

.font12 {
  font-size: 12px;
}

.padding16 {
  padding: 16px 0px;
}

.paddingLeft16 {
  padding-left: 16px;
}

.calendarBox {}

.marginLeft45 {
  margin-left: 45px;
}

.paddingHeight {
  padding: 20px 0px;
}

.fontWeight {
  font-weight: bold;
  font-size: 18px;
  color: #1f2e4d;
}

.cursor {
  cursor: pointer;
}

.dateStyle {
  height: 60px;
  align-items: center;
}

.dateWeek {
  font-weight: bold;
  font-size: 20px;
  margin-top: 5px;
}

.dateDay {
  font-size: 12px;
}

.paddingBottom {
  padding-bottom: 16px;
}

.paddingLeft {
  padding-left: 10px;
}

.width60 {
  width: 60px;
}

.dateWeekTip {
  font-size: 12px;
  font-weight: normal;
  color: #86909c;
}

.checkTimeStyle {
  height: 100%;
  border-radius: 6px 0px 0px 6px;
}

.expirationDate {
  border-left: 5px solid #006dfe;
  color: #006dfe;
  background: #f2f7ff;
}

.maintenance {
  border-left: 5px solid #0dbe75;
  color: #0dbe75;
  background: #e5f7f0;
}

.expireStyle {
  border-left: 5px solid #bbbbbb;
  color: #999999;
  background: #eeeeee;
}

.checkMeetingTitle {
  font-size: 14px;
  font-weight: bold;
  padding-left: 17px;
  padding-top: 5px;
}

.checkMeetingContent {
  font-size: 12px;
  padding-left: 17px;
  padding-top: 5px;
}

.meetingPopoverTitle {
  font-size: 16px;
  font-weight: 500;
  background: #ebeeff;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  font-weight: 500;
  color: #1f2e4d;
  width: 450px;

  img {
    width: 16px;
    height: 16px;
  }

  .meetingPopoverTitleText {
    font-size: 16px;
    font-weight: 500;
    margin-left: 12px;
    width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.meetingPopovercontent {
  display: flex;
  align-items: center;
  padding-left: 16px;
  color: #1f2e4d;
  margin-top: 14px;

  img {
    width: 14px;
    height: 14px;
  }

  .meetingPopoverTitleText {
    font-size: 14px;
    margin-left: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.meetingPopoverFoot {
  display: flex;
  justify-content: flex-end;
  margin: 0 16px;
  padding: 16px 0px;
  border-top: 1px solid #eeeeee;
  margin-top: 16px;
}

.choose {
  background-color: #eeeeee !important;
}

.checkDate {
  color: #006dfe !important;
}

.unCheckDate {
  color: #86909c;
}

.paddingLeft {
  padding-left: 12px;
}

.overFlowHide {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.cursorPointer {
  cursor: pointer;
}
</style>

<style>
.el-popover {
  padding: 0px !important;
}
</style>
